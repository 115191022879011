import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { USER_DISTRIBUTOR_URL, USER_URL } from '../../../../../environments/environment';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import { IPageableList } from '../../../models/pageable';
import { IUser, UserCreatePayload, UserRemoveParams, UserUpdatePayload, UsersSearchParams } from '../../../models/user';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private readonly http: HttpClient) { }

  findAllUsers(searchUserParams: Partial<UsersSearchParams>): Observable<IPageableList<IUser>> {
    const params = objectToParams(searchUserParams);
    return this.http.get<IPageableList<IUser>>(`${USER_DISTRIBUTOR_URL}/v1/user`, { params });
  }

  updateUser(payload: UserUpdatePayload): Observable<IUser> {
    return this.http.put<IUser>(`${USER_DISTRIBUTOR_URL}/v1/user`, payload);
  }

  createUser(payload: UserCreatePayload): Observable<IUser> {
    return this.http.post<IUser>(`${USER_DISTRIBUTOR_URL}/v1/user`, payload);
  }

  removeUsers(payload: UserRemoveParams): Observable<any> {
    const params = objectToParams(payload);
    return this.http.delete<any>(`${USER_DISTRIBUTOR_URL}/v1/user`, { params });
  }
}
