import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from '../../actions/saleplan/product.actions';
import { LoadingStatus } from '@Mesh/core/models/external/loadable';
import { IManufacturer, Manufacturer } from '@Mesh/core/models/manufacturer';
import { IProduct, Product, ProductsData } from '@Mesh/core/models/product';
import { ICategory } from '../../../core/models/category';
import { IBrand } from '../../../core/models/brand';

export interface State {
  products: ProductsData;
  productsForShoppingCart: ProductsData;
  productsWithOrders: Product[];
  productLoadingStatus: LoadingStatus;
  allProducts: IProduct[];
  allProductsTotalCount: number;
  productsByIds: IProduct[];
  topLevelCategories: ICategory[];
  categories: ICategory[];
  manufacturers: IManufacturer[];
  brands: IBrand[];
}

export const initialState: State = {
  products: null,
  productsForShoppingCart: null,
  productsWithOrders: [],
  productLoadingStatus: LoadingStatus.NotLoaded,
  allProducts: [],
  allProductsTotalCount: 0,
  productsByIds: [],
  topLevelCategories: [],
  categories: [],
  manufacturers: [],
  brands: [],
};

const productReducer = createReducer(
  initialState,
  on(fromActions.loadProducts, (state) => ({ ...state, productLoadingStatus: LoadingStatus.Loading })),
  on(fromActions.productsLoaded, (state, { products }) => {
    if (products?.number === 0) {
      return { ...state, products, productLoadingStatus: LoadingStatus.Loaded };
    }
    const newProducts = products?.content;
    return newProducts && state.products ? { ...state, productLoadingStatus: LoadingStatus.Loaded, products: { ...state.products, content: state.products.content.concat(newProducts), number: products.number } } : { ...state, productLoadingStatus: LoadingStatus.Loaded, products };
  }),
  on(fromActions.productsForShoppingCartLoaded, (state, { products }) => {
    return { ...state, productsForShoppingCart: products, number: products.number };
  }),
  on(fromActions.loadProductsWithOrders, state => ({ ...state, productsWithOrders: null })),
  on(fromActions.productsWithOrdersLoaded, (state, { products }) => ({ ...state, productsWithOrders: products })),
  on(fromActions.clearProducts, (state) => ({ ...state, products: { ...initialState.products }, productsWithOrders: { ...initialState.productsWithOrders } })),

  on(fromActions.loadAllProductSuccess, (state, { data }) => {
    return {
      ...state,
      allProducts: [...state.allProducts, ...data.content],
      allProductsTotalCount: data.totalElements
    };
  }),

  on(fromActions.loadProductsByProductIdsSuccess, (state, { data }) => {
    console.log('loadProductsByProductIdsSuccess', data);
    return {
      ...state,
      productsByIds: data.content
    };
  }),

  on(fromActions.resetAllProduct, (state) => {
    return {
      ...state,
      allProducts: [],
      allProductsTotalCount: 0
    };
  }),

  on(fromActions.loadTopLevelCategoriesSuccess, (state, { data }) => {
    return {
      ...state,
      topLevelCategories: data
    };
  }),

  on(fromActions.loadAllCategoriesSuccess, (state, { data }) => {
    return {
      ...state,
      categories: data
    };
  }),

  on(fromActions.loadBrandsSuccess, (state, { data }) => {
    return {
      ...state,
      brands: data
    };
  }),

  on(fromActions.loadManufacturerSuccess, (state, { data }) => {
    return {
      ...state,
      manufacturers: data
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return productReducer(state, action);
}

export const getProducts = (state: State) => state.products;
export const getProductLoadingStatus = (state: State) => state.productLoadingStatus;
export const getProductsWithOrders = (state: State) => state.productsWithOrders;

export const getAllProducts = (state: State) => state.allProducts;
export const getProductsByIds = (state: State) => state.productsByIds;
export const getTopLevelCategories = (state: State) => state.topLevelCategories;
export const getCategories = (state: State) => state.categories;
export const getManufacturers = (state: State) => state.manufacturers;
export const getBrands = (state: State) => state.brands;
export const getAllProductsTotalCount = (state: State) => state.allProductsTotalCount;