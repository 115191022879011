import { Action, createReducer, on } from '@ngrx/store';
import { DistroTabTypes } from '@Mesh/pages/distro/distro.types';
import * as fromActions from './distro.actions';
import { Distributor } from '../../../core/models/distributor';
export const distroFeatureKey = 'distro';

export interface DistroState {
  stepsCount: number;
  categoryStepsCount: number;
  companyInfoValid: boolean;
  categoryValid: boolean;
  productPriceValid: boolean;
  outletValid: boolean;
  companyData: Partial<Distributor>;
  distroTab: number;
  currentDistributor: Distributor;
  distributorList: Distributor[];
}

export const initialState: DistroState = {
  stepsCount: 0,
  categoryStepsCount: 0,
  companyInfoValid: false,
  categoryValid: false,
  productPriceValid: false,
  outletValid: false,
  distroTab: DistroTabTypes.COMPANY_INFO,
  companyData: null,
  currentDistributor: null,
  distributorList: [],
};

export const distroReducer = createReducer(
  initialState,
  on(fromActions.setLineActiveStep, (state, { stepsCount }) => ({ ...state, stepsCount })),
  on(fromActions.setCategoryLineActiveStep, (state, { stepsCount }) => ({ ...state, categoryStepsCount: stepsCount })),
  on(fromActions.setProductPriceValid, (state, { valid }) => ({ ...state, productPriceValid: valid })),
  on(fromActions.setCategoryValid, (state, { valid }) => ({ ...state, categoryValid: valid })),
  on(fromActions.setOutletValid, (state, { valid }) => ({ ...state, outletValid: valid })),
  on(fromActions.setCompanyInfoValid, (state, { valid }) => ({ ...state, companyInfoValid: valid })),
  on(fromActions.setCompanyData, (state, { companyData }) => ({ ...state, companyData })),
  on(fromActions.selectDistroTab, (state, { tab }) => ({ ...state, distroTab: tab })),
  on(fromActions.updateDistributorSuccess, (state, { distributor }) => ({ ...state, currentDistributor: distributor })),
  on(fromActions.createDistributorSuccess, (state, { distributor }) => ({ ...state, currentDistributor: distributor })),
  on(fromActions.findOneDistributorSuccess, (state, { distributor }) => ({ ...state, currentDistributor: distributor })),
  on(fromActions.findAllDistributorsSuccess, (state, { distributors }) =>
  ({
    ...state,
    distributorList: [...state.distributorList, ...distributors],
  })),
  on(fromActions.resetAllDistributors, (state) =>
  ({
    ...state,
    distributorList: [],
  })),
  on(fromActions.deleteDistributorSuccess, (state, { id }) =>
  ({
    ...state,
    distributorList: state.distributorList.filter(item => item.id !== id),
  })),
  on(fromActions.resetCurrentDistributors, (state) =>
  ({
    ...state,
    currentDistributor: null,
  })),
);

export function reducer(state: DistroState, action: Action): any {
  return distroReducer(state, action);
}
export const selectedStepsCount = (state: DistroState): number => state.stepsCount;
export const selectedCategoryStepsCount = (state: DistroState): number => state.categoryStepsCount;
export const selectedCompanyInfoValid = (state: DistroState): boolean => state.companyInfoValid;
export const selectedCategoryValid = (state: DistroState): boolean => state.categoryValid;
export const selectedProductPriceValid = (state: DistroState): boolean => state.productPriceValid;
export const selectedOutletValid = (state: DistroState): boolean => state.outletValid;
export const selectedCompanyData = (state: DistroState): Partial<Distributor> => state.companyData;
export const selectedDistroTab = (state: DistroState): DistroTabTypes => state.distroTab;
export const selectedCurrentDistributor = (state: DistroState): Distributor => state.currentDistributor;
export const selectedDistributorList = (state: DistroState): Distributor[] => state.distributorList;