export interface Country {
  id: number;
  name: string;
}

export class Region {
  id: number;
  name: string;
  country: Country;

  constructor(id?: number, name?: string, country?: Country) {
    this.id = id;
    this.name = name;
    this.country = country;
  }
}
export class City {
  id: number;
  name: string;
  region: Region;
  country: Country;

  constructor(id: number, name: string, region: Region, country?: Country) {
    this.id = id;
    this.name = name;
    this.region = region;
    this.country = country;
  }
}

export interface Criteria {
  size?: number;
  page?: number;
  sort?: string;
  direction?: string;
}

export interface CitiesCriteria extends Criteria {
  cityNamePattern?: string;
  regionId?: number;
}


export interface RegionCriteria extends Criteria {
  regionNamePattern?: string;
}

export interface CountrySearchParams {
  name: string;
  pageSize: number;
  pageNumber: number;
  sort: string[];
}

export interface CountryCreatePayload {
  id: number;
  name: string;
}

export interface CountryRemoveParams {
  id: number;
}

export interface RegionSearchParams {
  distrIds: number[]
  pageSize: number
  pageNumber: number
  sort: string[]
  countryIds: number[]
  name: string
  ids?: number[]
}

export interface RegionCreatePayload {
  id: number;
  name: string;
  countryId: number;
}

export interface RegionRemoveParams {
  id: number;
}

export interface CitySearchParams {
  distrIds: number[]
  pageSize: number
  pageNumber: number
  sort: string[]
  ids: number[]
  countryIds: number[]
  regionIds: number[]
  name: string[]
  namePattern: string
}

export interface CityCreatePayload {
  id: number;
  countryId: number;
  regionId: number;
  name: string;
}
export interface CityRemoveParams {
  id: number;
}
export interface PostalCodeStreetSearchParams {
  distrIds: number[]
  pageSize: number
  pageNumber: number
  sort: string[]
  ids: number[]
  street: string
  district: string
  cityId: number
}
export interface PostalCodeStreet {
  id: number;
  externalId: string;
  fiasExternalId: string;
  street: string;
  district: string;
  city: City;
}
