import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';
import { select } from '@ngrx/store';
import { getApplicationSettingsLoadingStatus, getCategoriesValue } from '@Mesh/store/selectors/startup/application-settings.selectors';
import { loadStepStatuses } from '@Mesh/store/actions/task/tasks.actions';
import { selectSelectedOutletId } from '@Mesh/store/selectors/deprecated/outlets.selectors';
import { getCurrentTask } from '@Mesh/store/selectors/task/tasks.selectors';
import { ActivatedRoute, Params } from '@angular/router';
import { getTaskProgress } from '@Mesh/store/actions/task/task-progress.actions';
import { getUser, selectUserDistributorRolesPermissions, selectUserRoleType } from '../../../store/selectors/auth/auth.selector';
import { UserDistributorRoleType } from '../../models/user';
import { Logout } from '../../../store/actions/auth/auth.actions';
import { asmMenuLinks, defaultMenuItems, ownerMenuLinks, type1MenuLinks, type2MenuLinks } from './menu-list';

@Component({
  selector: 'iql-condensed-layout',
  templateUrl: './condensed.component.html',
  styleUrls: ['./condensed.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CondensedComponent extends RootLayout implements OnInit {
  applicationSettingsState$ = this.store.pipe(select(getApplicationSettingsLoadingStatus));
  selectSelectedOutletId$ = this.store.pipe(select(selectSelectedOutletId));
  getCurrentTask$ = this.store.pipe(select(getCurrentTask));
  userDistributorRoles$ = this.store.select(selectUserDistributorRolesPermissions);
  userRoleType$ = this.store.select(selectUserRoleType);
  menuLinks: any[] = defaultMenuItems;
  currentUser$ = this.store.select(getUser);

  selectedOutletId;
  currentTask;
  userRoleType: UserDistributorRoleType;

  private getParams(route: ActivatedRoute): Params {
    let params = route.snapshot.params;
    params = { ...route.snapshot.queryParams, ...params };
    if (route.children) {
      for (const r of route.children) {
        params = { ...this.getParams(r), ...params };
      }
    }
    return params;
  }

  ngOnInit(): void {
    this.userRoleType$.subscribe(role => {
      this.userRoleType = role;
      switch (role) {
        case UserDistributorRoleType.ADMIN:
          this.menuLinks = defaultMenuItems;
          break
        case UserDistributorRoleType.OWNER:
          this.menuLinks = ownerMenuLinks;
          break;
        case UserDistributorRoleType.TYPE_1:
          this.menuLinks = type1MenuLinks;
          break;
        case UserDistributorRoleType.TYPE_2:
          this.menuLinks = type2MenuLinks;
          break;
        case UserDistributorRoleType.ASM:
          this.menuLinks = asmMenuLinks;
          break;
        default:
          this.menuLinks = defaultMenuItems;
          break;
      }
    });
    this.selectSelectedOutletId$.subscribe((selectedOutletId) => {
      this.selectedOutletId = selectedOutletId;
    });
    this.getCurrentTask$.subscribe((currentTask) => {
      this.currentTask = currentTask;
    });
    this.feathersService.service('tasks').on('shouldUpdate', ({ taskOutletClientId, clientId, id }) => {
      const params = this.getParams(this.route);
      if (+params.taskOutletClientId === taskOutletClientId && this.currentTask && this.currentTask.id === id) {
        this.store.dispatch(getTaskProgress({ clientIds: [clientId], taskId: id }));
      }
    });
    this.feathersService.service('task-steps').on('shouldUpdate', ({ taskOutletClientId, clientId, id }) => {
      const params = this.getParams(this.route);
      if (this.selectedOutletId === taskOutletClientId && +params.clientId === clientId) {
        this.store.dispatch(loadStepStatuses({ taskOutletClientId, stepId: [id] }));
      }
    });
  }

  logout() {
    this.store.dispatch(Logout());
  }
}
