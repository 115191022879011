export enum DistroTabTypes {
  COMPANY_INFO = 1,
  CLIENT_AND_OUTLET = 2,
  CATEGORIES = 3,
  PRICING = 4
}

export enum DistroCategoryTypes {
  CATEGORIES,
  SUB_CATEGORIES,
  CREATORS,
  BRANDS,
  PRODUCTS
}


