import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from './store/reducers';
import { init } from './store/actions/auth/auth.actions';
import * as moment from 'moment';
import { LanguageService } from './core/services/chat/language.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'iql-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    public readonly _store: Store<State>,
    public languageService: LanguageService,
    private swUpdate: SwUpdate) {
    this.languageService.setup();
    moment.locale('ru');
    this._store.dispatch(init());
  }

  ngAfterViewInit() {
    this.checkForUpdates();
  }

  private checkForUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.activateUpdate();
      });
    }
  }

  private activateUpdate() {
    this.swUpdate.activateUpdate().then(() => {
      console.log('new version available');
      window.location.reload();
    });
  }

}
