import { HttpParams } from '@angular/common/http';

export function objectMap<T>(object: any, mapFn: any, arg: T): T {
  return Object.keys(object).reduce(function (result, key) {
    result[key] = mapFn(object[key], key);
    return result;
  }, arg);
}

export function objectForEach(object: any, fn: any): void {
  Object.keys(object).forEach(key => {
    fn(object[key], key, object);
  });
}

export function getRandomItem(array: any[]): any {
  return array[Math.floor((Math.random() * array.length))];
}

export function uniqByKeepFirst(a: any, key: any): any {
  const seen = new Set();
  return a.filter((item: any) => {
    const k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
}

export const objectToParams = (obj: unknown, allowEmptyValue = true): HttpParams => {
  const strObj: { [key: string]: string } = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (key !== undefined && key !== null && value !== undefined && value !== null && (allowEmptyValue || value.toString())) {
      strObj[key] = value.toString();
    }
  });
  return new HttpParams({ fromObject: strObj });
};

export function convertArrayToObject<T>(array: T[], key: string | number): Record<string, T> {
  const initialValue: Record<string, T> = {};
  return array.reduce((obj: any, item: { [x: string]: any; }) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
}
