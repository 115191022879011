import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {CoefficientType, CoefficientValueType} from '@Mesh/pages/goals/components/create-goals/createGoalTypes';

@Component({
  selector: 'iql-percent-coefficient',
  templateUrl: './percent-coefficient.component.html',
  styleUrls: ['./percent-coefficient.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PercentCoefficientComponent {
  @Input()
  labelText: string;

  @Input()
  labelWidth: string;

  @Input()
  public isItem: boolean;

  @Input()
  public placeholder: string;

  @Input()
  public coefficientValueType: CoefficientValueType;

  @Input()
  isRequired: boolean;

  @Output()
  public selectData: EventEmitter<{ type: CoefficientType, value: number }[]> = new EventEmitter<{type: CoefficientType; value: number}[]>();

  CoefficientType = CoefficientType;
  CoefficientValueType = CoefficientValueType;

  @Input()
  data: { type: CoefficientType, value: number }[];

  changeValue(value: number, index: number): void {
    this.data[index].value = value;
    this.selectData.emit(this.data);
  }
}
