import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLocation from './location.reducer';

export const getLocationState =
  createFeatureSelector<fromLocation.State>('location');

export const selectCities = createSelector(
  getLocationState,
  fromLocation.getCities
);

export const selectRegions = createSelector(
  getLocationState,
  fromLocation.getRegions
);

export const selectCountries = createSelector(
  getLocationState,
  fromLocation.getCountries
);
