import { createAction, props } from '@ngrx/store';
import {
  IStockInfoResponse,
  ITaskAssignment,
  ITaskOutlet,
  TaskClientData,
  TaskData,
  TaskInfo,
  TaskStep,
  TaskStepStatusResponse,
  CriteriaTradeAgent,
  TradeAgent,
  Division,
  Position,
  ResponseTradeAgent,
  TaskTemplate,
  StepTemplate,
  StepTemplatePayload,
  TaskTemplatePayload,
  TaskTemplateBody,
  TaskOutletAssignPayload,
  CriteriaSearchLearnModule,
  CriteriaClientTask,
  Task,
  CriteriaSearchTask,
  TaskClient
} from '@Mesh/core/models/task';
import { Product } from '@Mesh/core/models/product';
import { BonusClient } from '@Mesh/core/models/bonus/bonus-client';
import { ErrorType } from '@Mesh/core/models/APPmodels/errors';
import { IClientsForTask } from '@Mesh/core/models/client';
import { IClientsQueryParams } from '@Mesh/core/models/query-params';
import { LearnModules } from '../../../core/models/learn-modules';
import { AgentUser, AgentUserSearchParams } from '../../../core/models/agent-user';
import { AgentDivision, AgentDivisionSearchParams } from '../../../core/models/agent-division';
import { AgentPosition } from '../../../core/models/agent-position';

export enum TaskActionsEnum {
  updateTaskClientsOutlets = '[Task] update task clients \ outlets',
  addTaskOutlet = '[Task] add task outlets'
}

export const loadCompletedTasks = createAction('[Task] load completed tasks', props<{
  pageNumber?: number,
  brandId?: number,
  clientId?: number,
  addressId?: number,
  showOnlyIsSuccess: boolean
}>());
export const completedTasksLoaded = createAction('[Task] completed tasks loaded', props<{ tasks: TaskData }>());
export const completedTasksLoadError = createAction('[Task] completed task load error');

export const loadTasks = createAction('[Task] load tasks', props<{ criteria?: Partial<CriteriaSearchTask> }>());
export const tasksLoaded = createAction('[Task] tasks loaded', props<{ tasks: TaskData }>());
export const tasksLoadError = createAction('[Task] tasks load error');
export const createTask = createAction('[Task] create task', props<{ task: TaskInfo, taskAssignment: ITaskAssignment }>());
export const taskCreated = createAction('[Task] task created', props<{ task: any }>());
export const updateTask = createAction('[Task] update task', props<{ task: Partial<TaskInfo>, taskAssignment: ITaskAssignment }>());
export const taskUpdated = createAction('[Task] task updated', props<{ task: any }>());
export const taskUpdateError = createAction('[Task] tasks update error');
export const setTaskUpdateInit = createAction('[Task] tasks update status set to init');

export const patchTask = createAction('[Task] patch task', props<{ task: Partial<TaskInfo>, id: number }>());
export const taskPatched = createAction('[Task] task patched', props<{ task: any, id: number }>());
export const addTaskOutletSuccess = createAction('[Task] add task outlet success', props<{ id: number }>());

export const addTaskOutlet = createAction(TaskActionsEnum.addTaskOutlet, props<{ taskOutlets: ITaskOutlet[], assignment: ITaskAssignment, taskId: number, actionOnSuccess: any, autoAssignment?: boolean }>());
export const updateTaskClientsOutlets = createAction(TaskActionsEnum.updateTaskClientsOutlets, props<{ assignment: ITaskAssignment, taskId: number, actionOnSuccess: any }>());
export const taskClientsOutletsUpdated = createAction('[Task] task clients \ outlets updated');

export const loadTask = createAction('[Task] load task', props<{ taskId: number }>());
export const clearTask = createAction('[Task] clear task');
export const taskLoaded = createAction('[Task] task loaded', props<{ task: TaskInfo }>());

export const loadAllClientsOutlets = createAction('[Task] load all clients outlets', props<{ params: IClientsQueryParams; reset?: boolean }>());
export const loadAllClientsOutletsLoaded = createAction('[Task] all clients outlets loaded', props<{ data: IClientsForTask[], reset: boolean }>());
export const loadTaskClientOutlets = createAction('[Task] load task client outlets', props<{ taskId: number, clientId: number }>());
export const taskClientOutletsLoaded = createAction('[Task] task client outlets loaded', props<{ taskClients: TaskClientData }>());

export const loadPlanNames = createAction('[Task] load plan names');
export const planNamesLoaded = createAction('[Task] plan names loaded', props<{ planNames: { planName: string, plan_id: number }[] }>());

export const loadPlanStock = createAction('[Task] load plan stock', props<{ outletId: number, recommendedOrderConfigId: number, date: string }>());
export const clearPlanStock = createAction('[Task] clear plan stock');
export const planStockLoaded = createAction('[Task] plan stock loaded', props<{ stockInfo: IStockInfoResponse[], productsInfo: Product[] }>());
export const planStockError = createAction('[Task] plan stock error');

export const loadStepStatuses = createAction('[Task] load step statuses', props<{ stepId: number[], taskOutletClientId: number }>());
export const stepStatusesLoaded = createAction('[Task] step statuses loaded', props<{ steps: TaskStepStatusResponse[] }>());

export const createSteps = createAction('[Task] create steps', props<{ steps: TaskStep[], taskId: number, assignment: ITaskAssignment, actionOnSuccess: any, autoAssignment?: boolean }>());
export const stepUpdateError = createAction('[Task] update step error', props<{ error }>());
export const stepsCreated = createAction('[Task] steps created', props<{ steps: TaskStep[], taskId: number }>());
export const proceedWithStep = createAction('[Task] accept or decline step', props<{ taskStep: TaskStepStatusResponse }>());
export const proceedWithStepSucceeded = createAction('[Task] accept or decline step succeeded');

export const taskBonusAction = {
  loadPredictBonusClient: createAction('[Bonus] Load client predict', props<{ clientId: number }>()),
  loadPredictBonusClientSuccess: createAction('[Bonus] Load client predict success', props<{ data: BonusClient }>()),
  loadPredictBonusClientError: createAction('[Bonus] Load client predict error', props<{ error: ErrorType }>()),
};

export const tradeAgentsAction = {
  loadTradeAgents: createAction('[Trade agents] Load trade agents', props<Partial<AgentUserSearchParams>>()),
  loadTradeAgentsSuccess: createAction('[Trade agents] Load trade agents success', props<{ data: Array<AgentUser> }>()),
  loadTradeAgentsError: createAction('[Trade agents] Load trade agents error'),
  resetTradeAgents: createAction('[Trade agents] Reset trade agents'),
};

export const divisionsAction = {
  loadDivisions: createAction('[Divisions] Load divisions', props<Partial<AgentDivisionSearchParams>>()),
  loadDivisionsSuccess: createAction('[Divisions] Load divisions success', props<{ data: Array<AgentDivision> }>()),
  loadDivisionsError: createAction('[Divisions] Load divisions error')
};

export const positionsAction = {
  loadPositions: createAction('[Positions] Load positions', props<Partial<AgentDivisionSearchParams>>()),
  loadPositionsSuccess: createAction('[Positions] Load positions success', props<{ data: Array<AgentPosition> }>()),
  loadPositionsError: createAction('[Positions] Load positions error')
};

export const createTaskTemplate = createAction('[Task] Create Task Template', props<{ payload: TaskTemplatePayload }>());
export const createTaskTemplateSuccess = createAction('[Task] Create Task Template Success', props<{ data: TaskTemplate }>());
export const createTaskTemplateFailure = createAction('[Task] Create Task Template Failure', props<{ error: any }>());

export const createStepTemplate = createAction('[Task] Create Step Template', props<{ saveTemplateSteps: any, payload: StepTemplatePayload[] }>());
export const createStepTemplateSuccess = createAction('[Task] Create Step Template Success', props<{ data: StepTemplate[] }>());
export const createStepTemplateFailure = createAction('[Task] Create Step Template Failure', props<{ error: any }>());

export const loadAllTaskTemplates = createAction('[Task] Load All Task Templates');
export const loadAllTaskTemplatesSuccess = createAction('[Task] Load All Task Templates Success', props<{ data: Array<TaskTemplate> }>());
export const loadAllTaskTemplatesFailure = createAction('[Task] Load All Task Templates Failure', props<{ error: any }>());

export const loadAllStepTemplates = createAction('[Task] Load All Step Templates');
export const loadAllStepTemplatesSuccess = createAction('[Task] Load All Step Templates Success', props<{ data: Array<StepTemplate> }>());
export const loadAllStepTemplatesFailure = createAction('[Task] Load All Step Templates Failure', props<{ error: any }>());

export const createTaskByTemplate = createAction('[Task] Create Task By Template', props<{ templateId: number, payload: TaskTemplateBody }>());
export const createTaskByTemplateSuccess = createAction('[Task] Create Task By Template Success', props<{ data: { id: number } }>());
export const createTaskByTemplateFailure = createAction('[Task] Create Task By Template Failure', props<{ error: any }>());

export const loadModulesLearn = createAction('[Task] Load Modules Learn', props<{ criteria: CriteriaSearchLearnModule }>());
export const loadModulesLearnSuccess = createAction('[Task] Load Modules Learn Success', props<{ data: any }>());
export const loadModulesLearnFailure = createAction('[Task] Load Modules Learn Failure', props<{ error: any }>());
export const resetModulesLearn = createAction('[Task] Reset Modules Learn');

export const loadClientTasks = createAction('[Task] Load Client Tasks', props<{ criteria: Partial<CriteriaClientTask> }>());
export const loadClientTasksSuccess = createAction('[Task] Load Client Tasks Success', props<{ data: TaskClient[] }>());
export const loadClientTasksFailure = createAction('[Task] Load Client Tasks Failure', props<{ error: any }>());
export const resetClientTasksFailure = createAction('[Task] Reset Client Tasks');
