import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { combineLatest } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AgentVisitCreatePayload } from '../../../core/models/agent';
import { TaskStep, TaskVisitCreatePayload } from '../../../core/models/task';
import { AgentService } from '../../../core/services/api/agent/agent.service';
import { TasksService } from '../../../core/services/api/task/tasks.service';
import { OutletActionsType } from '../../../pages/routes-and-visits/route-assignment/outlet-checkbox/outlet-actions';
import * as fromActions from '../../actions/agent/agent.actions';

@Injectable()
export class AgentEffects {

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private agentService: AgentService,
    private tasksService: TasksService) { }

  searchAgentVisits$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.findAgentVisits),
      switchMap(({ agentVisitPlanSearchParams }) => this.agentService.searchAgentVisits(agentVisitPlanSearchParams)
        .pipe(
          map(data => fromActions.findAgentVisitSuccess({ agentVisits: data.content })),
          catchError(err => ([fromActions.findAgentVisitsFailure(err)]))
        )
      )
    ));

  createVisit$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.createVisit),
      map(action => action.payload),
      switchMap(({ taskTemplateId, ...payload }) => {
        return this.tasksService.createTaskByTemplate(taskTemplateId, {
          name: null,
          cost: null,
          active: null,
          imageUrl: null,
          dataOfCreation: moment().toISOString(),
          comment: payload.comment,
          autoAssignment: null,
          autoAssignmentType: null,
          autoAssignmentDayCount: null,
          permissionType: payload.permissionType,
          required: true,
          dateStart: moment(payload.visitDate).utc()
            .set({
              hour: 0,
              minute: 0,
              second: 0
            }).toISOString(),
          dateEnd: moment(payload.visitDate).utc()
            .set({
              hour: 23,
              minute: 59,
              second: 0
            }).toISOString(),
        })
          .pipe(
            map(({ id }) => fromActions.createVisitAssignTaskAndAdddress({ taskTemplateId, taskId: id, payload })),
            catchError(err => ([fromActions.createAgentVisitFailure(err)])),
          );
      })
    ));

  createStepByTemplate$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.createVisitAssignTaskAndAdddress),
      mergeMap(({ taskTemplateId, taskId, payload }) => {
        const stepsCreation = [];
        payload.steps.forEach((item, i) => stepsCreation.push(this.tasksService.createStepByTemplate(item.id, { ...item.body, taskId, step: i + 1 })));
        return combineLatest(stepsCreation)
          .pipe(
            map((data: TaskStep[]) => fromActions.createStepByTemplateSuccess({ data })),
            catchError(err => ([fromActions.createAgentVisitFailure(err)]))
          );
      })
    )
  );

  createTaskAddress$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.createVisitAssignTaskAndAdddress),
      switchMap(({ taskId, payload }) => this.tasksService.addTaskOutlet(taskId, [{
        addressId: payload.addressId,
        clientId: payload.clientId,
        userId: !payload.byAddress ? payload.userId : null,
      }])
        .pipe(
          map(data => fromActions.createAgentVisit({ taskId, payload })),
          catchError(err => ([fromActions.createAgentVisitFailure(err)]))
        )
      )
    ));

  assignTask$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.assignTaskAndVisitSuccess),
      switchMap(({ taskId, payload, visits }) => this.tasksService.addTaskClientsOutlets([{
        addressId: payload.addressId,
        clientId: payload.clientId,
        userId: !payload.byAddress ? payload.userId : null,
        mentorUserId: payload.byAddress ? payload.userId : null,
        agentVisitId: visits && visits[0].id,
        dateTimeStart: moment(payload.visitDate).utc().set({
          hour: payload.startVisitTime.hour,
          minute: payload.startVisitTime.minute,
          second: 0
        }).toISOString(),
        dateTimeEnd: moment(payload.visitDate).utc().set({
          hour: payload.endVisitTime.hour,
          minute: payload.endVisitTime.minute,
          second: 0
        }).toISOString(),
      }], taskId)
        .pipe(
          map(data => {
            if (payload.visitId) {
              return fromActions.editAgentVisitSuccess({ data: visits[0] });
            } else {
              return fromActions.createAgentVisitSuccess({ data: visits });
            }
          }),
          catchError(err => ([fromActions.createAgentVisitFailure(err)]))
        )
      )
    ));

  createAgentVisits$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.createAgentVisit),
      switchMap(({ taskId, payload }) => this.agentService.saveAgentVisit([toVisitItem(payload)])
        .pipe(
          map(data => {
            if (data && data.length) {
              return fromActions.assignTaskAndVisitSuccess({ taskId, payload, visits: data });
            } else {
              return fromActions.createAgentVisitFailure({ error: 'нет айди' });
            }
          }),
          catchError(err => ([fromActions.createAgentVisitFailure(err)]))
        )
      )
    ));

  editAgentVisits$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.editAgentVisit),
      switchMap(({ payload }) => this.agentService.saveAgentVisit([toVisitItem(payload)])
        .pipe(
          map(data => fromActions.editAgentVisitSuccess({ data: data[0] })),
          catchError(err => ([fromActions.createAgentVisitFailure(err)]))
        )
      )
    ));

  remoteAgentVisits$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.removeAgentVisit),
      switchMap(({ agentVisitPlanIds }) => this.agentService.removeAgentVisits({ agentVisitPlanIds })
        .pipe(
          map(data => fromActions.removeAgentVisitSuccess({ data: agentVisitPlanIds })),
          catchError(err => ([fromActions.removeAgentVisitFailure(err)]))
        )
      )
    ));
}

function toVisitItem(payload: Partial<TaskVisitCreatePayload>): AgentVisitCreatePayload {
  return {
    addressId: payload.addressId,
    distributorId: payload.distributorId,
    userId: payload.userId,
    byAddress: payload.byAddress,
    visitDate: moment(payload.visitDate).utc()
      .set({
        hour: 0,
        minute: 0,
        second: 0
      }).format('YYYY-MM-DD'),
    startVisitTime: payload.timeVisitType !== OutletActionsType.VISIT_WITHOUT_TIME
      ? moment(payload.visitDate).set({
        hour: payload.startVisitTime.hour,
        minute: payload.startVisitTime.minute,
        second: 0
      }).format('HH:mm') : null,
    endVisitTime: payload.timeVisitType !== OutletActionsType.VISIT_WITHOUT_TIME
      ? moment(payload.visitDate).set({
        hour: payload.endVisitTime.hour,
        minute: payload.endVisitTime.minute,
        second: 0
      }).format('HH:mm') : null,
  };
}
