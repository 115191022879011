import { createReducer, Action, on } from '@ngrx/store';
import * as fromActions from '../../actions/auth/auth.actions';
import { IUserData, IUserInfo } from '@Mesh/core/models/user';

export interface State {
  userInfo: IUserInfo;
}

export const initialState: State = {
  userInfo: null
};

const authReducer = createReducer(
  initialState,
  on(fromActions.getTokenSuccess, (state, { userInfo }) => ({ ...state, userInfo })),
  on(fromActions.refreshTokenSuccess, (state, { userInfo }) => ({ ...state, userInfo })),
  on(fromActions.setTokenSuccess, (state, { userInfo }) => ({ ...state, userInfo })),
  on(fromActions.Logout, (state) => ({ ...state, userInfo: null })),
  on(fromActions.ResetUserInfo, (state) => ({ ...state, userInfo: null })),
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}

export const getUser = (state: State) => state.userInfo?.user;
export const getToken = (state: State) => state.userInfo.accessToken;
export const getRefreshToken = (state: State) => state.userInfo.refreshToken;
