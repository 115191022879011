import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { USER_DISTRIBUTOR_URL } from '../../../../../environments/environment';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import { Client, ClientCommonSearchParams, ClientCreatePayload, ClientRemoveParams, ClientSearchByNameParams, ClientSearchParams, CommonClient } from '../../../models/clients';
import { IPageableList } from '../../../models/pageable';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  constructor(private readonly http: HttpClient) { }

  searchClients(searchAddressParams: Partial<ClientSearchParams>): Observable<IPageableList<Client>> {
    const params = objectToParams(searchAddressParams);
    return this.http.get<IPageableList<Client>>(`${USER_DISTRIBUTOR_URL}/v1/adm/client`, { params });
  }

  searchClientsByName(searchAddressParams: Partial<ClientSearchByNameParams>): Observable<IPageableList<Client>> {
    const params = objectToParams(searchAddressParams);
    return this.http.get<IPageableList<Client>>(`${USER_DISTRIBUTOR_URL}/v1/client`, { params });
  }


  saveClient(payload: ClientCreatePayload): Observable<Array<Client>> {
    return this.http.put<Array<Client>>(`${USER_DISTRIBUTOR_URL}/v1/adm/client`, payload);
  }

  createClient(payload: ClientCreatePayload): Observable<Array<Client>> {
    return this.http.post<Array<Client>>(`${USER_DISTRIBUTOR_URL}/v1/adm/client`, payload);
  }

  removeClients(payload: ClientRemoveParams): Observable<any> {
    const params = objectToParams(payload);
    return this.http.delete<any>(`${USER_DISTRIBUTOR_URL}/v1/adm/client`, { params });
  }

  searchCommonClient(payload: Partial<ClientCommonSearchParams>): Observable<IPageableList<CommonClient>> {
    return this.http.post<IPageableList<CommonClient>>(`${USER_DISTRIBUTOR_URL}/v1/common-client/details`, payload);
  }
}