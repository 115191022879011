import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { IUserData, UserDistributorRoleType } from '../../../models/user';
import { ownerMenuLinks, type1MenuLinks, type2MenuLinks, asmMenuLinks, defaultMenuItems } from '../menu-list';

@Component({
  selector: 'iql-user-header-info',
  templateUrl: './user-header-info.component.html',
  styleUrls: ['./user-header-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserHeaderInfoComponent implements OnInit {
  @Input() user: IUserData;
  @Input() userRoleType: string;
  @Output() logout = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }


  onClickLogout() {
    this.logout.emit();
  }

  get userRole() {
    switch (this.userRoleType) {
      case UserDistributorRoleType.ADMIN:
        return 'Супер Админ';
      case UserDistributorRoleType.OWNER:
        return 'Администратор';
      case UserDistributorRoleType.TYPE_1:
        return 'Офисный сотрудник тип 1';
      case UserDistributorRoleType.TYPE_2:
        return 'Офисный сотрудник тип 2';
      case UserDistributorRoleType.ASM:
        return 'АСМ';
      default:
        return 'Пользователь';
    }
  }
}
