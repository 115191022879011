import { ITaskOutlet, StepTemplate, StepTemplatePayload, TaskOutletAddress, TaskTemplate, TaskTemplatePayload, TaskTemplateBody, TaskOutletAssignPayload, CriteriaSearchLearnModule, CriteriaClientTask, Task, TaskOutletAssign, CriteriaSearchTask, TaskClient, TaskAssignByTemplatesPayload, AssignByTemplatesAndOutletFilterPayload, CriteriaTaskAssignByTemplates, TaskAssignByTemplates } from './../../../models/task';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IStockInfoResponse, StepType, TaskData, TaskInfo, TaskProgress, TaskStep, TaskStepStatusResponse, CriteriaTradeAgent, TradeAgent, ResponseTradeAgent, Position, Division } from '../../../models/task';
import { catchError } from 'rxjs/operators';
import { SALEPLAN_URL, TASK_URL, SFA_URL, TASK_DISTRIBUTOR_URL, SALEPLAN_DISTRIBUTOR_URL } from '@Env/environment';
import { ProductsData } from '@Mesh/core/models/product';
import { BonusClient } from '@Mesh/core/models/bonus/bonus-client';
import { IClientsQueryParams, IQueryParams } from '@Mesh/core/models/query-params';
import { BonusConfiguration } from '@Mesh/core/models/bonus/bonus-configuration';
import { IResponse } from '@Mesh/core/models/IResponse';
import { IAutoOrder } from '@Mesh/pages/auto-orders/create-auto-order-types';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import { LearnModules } from '../../../models/learn-modules';
import { IPageableList } from '../../../models/pageable';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  private get contentTypeJson(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  private get httpOptions(): Object {
    return { headers: this.contentTypeJson } as Object;
  }

  constructor(private http: HttpClient) {
  }

  readonly baseUrl = `${TASK_URL}/task`;

  getClientTasks(criteria: Partial<CriteriaClientTask> = {}): Observable<IResponse<TaskClient[]>> {
    const params = objectToParams(criteria);
    return this.http.get<IResponse<TaskClient[]>>(`${TASK_URL}/client-task`, { params });
  }

  getClientTasksOverview(addressIds: number[]): Observable<TaskData> {
    let params = new HttpParams();

    if (addressIds) {
      params = params.append('addressIds', `${addressIds}`);
    }
    return this.http.get<TaskData>(`${TASK_URL}/client-task/overview`, {
      params,
      headers: this.contentTypeJson
    });
  }

  // getDivisions(): Observable<{ data: Array<Division> }> {
  //   return this.http.get<{ data: Array<Division> }>(`${SFA_URL}/divisions`, {
  //     headers: this.contentTypeJson
  //   });
  // }

  // getPositions(): Observable<{ data: Array<Position> }> {
  //   return this.http.get<{ data: Array<Position> }>(`${SFA_URL}/positions`, {
  //     headers: this.contentTypeJson
  //   });
  // }

  // getTradeAgents(criteria: CriteriaTradeAgent): Observable<ResponseTradeAgent> {
  //   const params = objectToParams(criteria);

  //   return this.http.get<ResponseTradeAgent>(`${SFA_URL}/user/search`, {
  //     params,
  //     headers: this.contentTypeJson
  //   });
  // }

  getTasks(criteria: Partial<CriteriaSearchTask> = {}): Observable<TaskData> {
    const params = objectToParams(criteria);
    return this.http.get<TaskData>(`${TASK_URL}/task`, { params });
  }

  getStepsStatuses(taskOutletClientId: number, stepId: number[]): Observable<any> {
    let params = new HttpParams().append('taskOutletClientId', `${taskOutletClientId}`);
    stepId.forEach(s => params = params.append('stepIds', `${s}`));

    return this.http.get<any>(`${this.baseUrl}/success`, {
      params,
      headers: this.contentTypeJson
    }).pipe(
      catchError(() => {

        return of([]);
      })
    );
  }

  getTaskOutletsByClientId(taskId: number, clientId: number): Observable<number[]> {
    const params = new HttpParams().append('taskId', `${taskId}`).append('clientId', `${clientId}`);

    return this.http.get<number[]>(`${this.baseUrl}/get/addressId`, {
      params,
      headers: this.contentTypeJson
    });
  }

  getTaskOutletIds(taskId: number): Observable<any[]> {
    const params = new HttpParams().append('taskId', `${taskId}`);

    return this.http.get<any[]>(`${this.baseUrl}/client/outlet`, {
      params,
      headers: this.contentTypeJson
    });
  }

  getTaskById(taskId: number): Observable<TaskInfo> {
    return this.http.get<TaskInfo>(`${this.baseUrl}/${taskId}`, this.httpOptions);
  }

  getTasksProgress(taskId: number, clientIds: number[]): Observable<TaskProgress[]> {
    let params = new HttpParams().append('taskId', `${taskId}`);

    clientIds.forEach(c => params = params.append('clientIds', `${c}`));

    return this.http.get<TaskProgress[]>(`${this.baseUrl}/progress/clients`, {
      params,
      headers: this.contentTypeJson
    });
  }

  //endregion

  //region Post/Put/Delete methods
  updateTask(taskInfo: Partial<TaskInfo>): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/${taskInfo.id}`, taskInfo, this.httpOptions);
  }

  createTask(taskInfo: TaskInfo): Observable<any> {
    delete taskInfo.id;
    return this.http.put<any>(`${this.baseUrl}/create`, taskInfo, this.httpOptions);
  }

  patchTask(taskInfo: Partial<TaskInfo>, id: number): Observable<any> {
    return this.http.patch<any>(`${this.baseUrl}/${id}`, taskInfo, this.httpOptions);
  }

  proceedWithStep(taskStep: TaskStepStatusResponse, stepType: StepType): Observable<any> {
    switch (stepType) {
      case StepType.calculateStock: {
        break;
      }

      case StepType.selectOption: {
        break;
      }

      case StepType.sendPhoto: {
        break;
      }

      case StepType.giveAnswer: {
        break;
      }

      case StepType.photoMonitoring: {
        break;
      }
    }

    return of();
  }


  addTaskClientsOutlets(payload: TaskOutletAssignPayload[], taskId: number): Observable<TaskOutletAssign> {
    return this.http.post<TaskOutletAssign>(`${this.baseUrl}/${taskId}/client/outlet`, payload, this.httpOptions);
  }

  addTaskOutlet(taskId: number, body: ITaskOutlet[]): Observable<TaskOutletAddress> {
    return this.http.post<TaskOutletAddress>(`${TASK_URL}/task-address/${taskId}`, body, this.httpOptions);
  }

  addTaskOutletsFromCsv(file: any, taskId: number): Observable<any> {
    //TODO: figure out how to pass it
    return this.http.post<any>(`${this.baseUrl}/add/client/csv?taskId=${taskId}`, { file }, this.httpOptions);
  }

  deleteTaskClientsOutlets(body: { clientId: number, addressId: number, dateTimeStart?: string, dateTimeEnd?: string }[], taskId: number): Observable<any> {
    const options = this.httpOptions;
    options['body'] = body;

    return this.http.delete<any>(`${this.baseUrl}/${taskId}/client/outlet`, options);
  }

  //endregion

  //endregion

  //region TaskStep methods

  updateStep(step: Partial<TaskStep>, stepId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/step/${stepId}`, step, this.httpOptions);
  }

  createStep(step: TaskStep, taskId: number): Observable<any> {
    delete step.id;
    return this.http.put<any>(`${this.baseUrl}/step/create`, { ...step, taskId }, this.httpOptions).pipe(
      catchError(e => {
        console.log(e);
        return of({});
      })
    );
  }

  deleteStep(stepId: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/step${stepId}`, this.httpOptions).pipe(
      catchError(e => {
        console.log(e);
        return of({});
      })
    );
  }

  //endregion

  getPlans(): Observable<{ planName: string, plan_id: number }[]> {
    return this.http.get<{ planName: string, plan_id: number }[]>(`${SALEPLAN_URL}/v1/sale-plan/plans?addressId=800587944`, this.httpOptions);
  }

  getStockInfo(outletId: number, planId: number, date: string): Observable<IStockInfoResponse[]> {
    return this.http.get<IStockInfoResponse[]>(`${SALEPLAN_URL}/v1/leftover/plan?addressId=${outletId}&planId=${planId}&registerAt=${date}`, this.httpOptions);
  }

  getProductsInfo(outletId: number, recommendedOrderConfigId: number): Observable<ProductsData> {
    return this.http.get<ProductsData>(`${SALEPLAN_URL}/v1/product?addressId=${outletId}&recommendedOrderConfigId=${recommendedOrderConfigId}&pageSize=999`, this.httpOptions);
  }

  getPredictBonusClient(clientId: number): Observable<BonusClient> {
    return this.http.get<BonusClient>(`${TASK_URL}/bonus/${clientId}/predict`);
  }

  getOrdersRecommendedConfigs(params: Partial<IClientsQueryParams>): Observable<IResponse<BonusConfiguration[]>> {
    return this.http.get<IResponse<BonusConfiguration[]>>(`${SALEPLAN_DISTRIBUTOR_URL}/v1/orders/configuration/recommended`, { params: <HttpParams>params });
  }

  createTaskTemplate(payload: TaskTemplatePayload): Observable<TaskTemplate> {
    return this.http.post<TaskTemplate>(`${TASK_URL}/task-template`, payload);
  }

  updateTaskTemplate(id: number, payload: TaskTemplatePayload): Observable<TaskTemplate> {
    return this.http.put<TaskTemplate>(`${TASK_URL}/task-template/${id}`, payload);
  }

  removeTaskTemplate(id: number): Observable<string> {
    return this.http.delete<string>(`${TASK_URL}/task-template/${id}`);
  }

  createStepTemplate(payload: StepTemplatePayload): Observable<StepTemplate> {
    delete payload.body.id;
    return this.http.post<StepTemplate>(`${TASK_URL}/step-template`, payload);
  }

  updateStepTemplate(id: number, payload: StepTemplatePayload): Observable<StepTemplate> {
    delete payload.body.id;
    return this.http.put<StepTemplate>(`${TASK_URL}/step-template/${id}`, payload);
  }

  removeStepTemplate(id: number): Observable<string> {
    return this.http.delete<string>(`${TASK_URL}/step-template/${id}`);
  }

  getTaskTemplateById(id: number): Observable<TaskTemplate> {
    return this.http.get<TaskTemplate>(`${TASK_URL}/task-template/${id}`);
  }

  getAllTaskTemplates(): Observable<Array<TaskTemplate>> {
    return this.http.get<Array<TaskTemplate>>(`${TASK_URL}/task-template`);
  }

  getAllStepTemplates(): Observable<Array<StepTemplate>> {
    return this.http.get<Array<StepTemplate>>(`${TASK_URL}/step-template`);
  }

  createTaskByTemplate(templateId: number, payload: TaskTemplateBody): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(`${TASK_URL}/task/create-by-template/${templateId}`, payload);
  }

  createStepByTemplate(templateId: number, payload: TaskStep): Observable<TaskStep> {
    return this.http.post<TaskStep>(`${TASK_URL}/task/step/create-by-template/${templateId}`, payload);
  }

  getModulesLearnSearch(criteria: CriteriaSearchLearnModule): Observable<LearnModules> {
    const params = objectToParams(criteria);
    return this.http.get<LearnModules>(`${SFA_URL}/search/learn`, { params });
  }

  createAndAssignByTemplates(payload: TaskAssignByTemplatesPayload): Observable<any> {
    return this.http.post<any>(`${TASK_URL}/task/create-and-assign`, payload);
  }

  createAndAssignByTemplatesAndOutletFilter(payload: AssignByTemplatesAndOutletFilterPayload): Observable<any> {
    return this.http.post<any>(`${TASK_URL}/task-assignment-by-address-filter`, payload);
  }

  getTaskAssignmentByAddressFilter(criteria: Partial<CriteriaTaskAssignByTemplates>): Observable<IPageableList<TaskAssignByTemplates>> {
    const params = objectToParams(criteria);
    return this.http.get<IPageableList<TaskAssignByTemplates>>(`${TASK_URL}/task-assignment-by-address-filter`, { params });
  }

  toggleTaskAssignmentByAddressFilter(id: number): Observable<TaskAssignByTemplates> {
    return this.http.put<TaskAssignByTemplates>(`${TASK_URL}/task-assignment-by-address-filter/${id}/toggle`, {});
  }
}
