export const environment = {
    production: true,
    translation: {
        supportedLanguages: ['ru', 'en'],
        default: 'ru',
        languages: {
            ru: 'assets/translations/ru.json',
            en: 'assets/translations/en.json',
        },
    },
};

export const FIREBASE = {
    apiKey: 'AIzaSyBIaurZB8ATPTt6Gy-xJuswZBN7Yi6Uk54',
    authDomain: 'sprint2-164b2.firebaseapp.com',
    databaseURL: 'https://sprint2-164b2.firebaseio.com',
    projectId: 'sprint2-164b2',
    storageBucket: 'sprint2-164b2.appspot.com',
    messagingSenderId: '89950498120',
    appId: '1:89950498120:web:786d9dcdabac727d2c977d',
    measurementId: 'G-6VMT7WZHEX',
};

export const SFA_URL = 'https://api.school.iqmart.pro/api';
export const SALEPLAN_URL = 'https://saleplan.iqmart.pro/api';
export const SALEPLAN_DISTRIBUTOR_URL = 'https://saleplan.distributor.iqmart.pro/api';
export const TASK_DISTRIBUTOR_URL = 'https://task.stage-v2.retail.iql.ru/api/v1';
export const TASK_URL = 'https://task.iqmart.pro/api/v1';
export const USER_URL = 'https://user.iqmart.pro/api/v1';
export const USER_DISTRIBUTOR_URL= 'https://user.distributor.iqmart.pro/api';
export const MESSAGING_URL = 'https://notifications.iqmart.pro/api/v1';
export const NODE_URL = 'https://chat.iqmart.pro';
export const BASE_URL = 'https://sprint2.avaha.ru';
export const SALEPLAN_IMAGES_URL = 'https://cdn.iqmart.pro';
export const CLOUD_URL = 'https://cloud.iqmart.pro/api/v1';
export const IMPORTER_FILES_URL = 'https://importer.iqmart.pro/api/v1';
export const CLOUD_UPLOAD_URL = 'https://cloud.iqmart.pro/api/v1/content/image';
export const CLOUD_UPLOAD_DOC_URL = 'https://cloud.iqmart.pro/api/v1/content/doc';
export const IMAGE_CLOUD_URL = 'https://static.cloud.iqmart.pro';
export const MESSAGING_WEBSOCKET_URL = 'wss://notifications.iqmart.pro/iq-messaging/socket/v1/';
export const IMAGES_URL = IMAGE_CLOUD_URL;
export const REPORTS_URL = 'https://data-report.iqmart.pro/api';

export const API_URL = BASE_URL + '/api/v1';
export const IMAGE_URL = API_URL + '/upload/image';
export const AUTH_URL = USER_URL + '/token';
export const DADATA_TOKEN = '88e3ff2b146c2f1c3ec374b96391348db45f7769';