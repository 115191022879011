import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSpaces',
})
export class NumberSpacesPipe implements PipeTransform {
  transform(value: any): string {
    if (typeof value !== 'number') return value as string
    value = value.toString()
    let interations = Math.floor(value.length/3)
    if (value.length%3 === 0) interations--
    for (let i = 0; i < interations; i++) {
      value = value.slice(0, value.length-(i+1)*3)+' '+value.slice(value.length-(i+1)*3)
    }
    return value;
  }
}
