import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { USER_DISTRIBUTOR_URL } from '../../../../../environments/environment';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import { DistributorSearchParams, DistributorRemoveParams, Distributor, DistributorRoleUser } from '../../../models/distributor';
import { IPageableList } from '../../../models/pageable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DistributorService {
  constructor(private readonly http: HttpClient) { }

  searchDistributor(searchDistributorParams: Partial<DistributorSearchParams>): Observable<IPageableList<Distributor>> {
    const params = objectToParams(searchDistributorParams);
    return this.http.get<IPageableList<Distributor>>(`${USER_DISTRIBUTOR_URL}/v1/distributor`, { params });
  }

  createDistributor(payload: Distributor): Observable<Distributor> {
    return this.http.post<Distributor>(`${USER_DISTRIBUTOR_URL}/v1/distributor`, payload);
  }

  updateDistributor(payload: Distributor): Observable<Distributor> {
    return this.http.put<Distributor>(`${USER_DISTRIBUTOR_URL}/v1/distributor`, payload);
  }

  removeDistributor(payload: DistributorRemoveParams): Observable<any> {
    const params = objectToParams(payload);
    return this.http.delete<any>(`${USER_DISTRIBUTOR_URL}/v1/distributor`, { params });
  }

  getAllDistributorRole(searchParams: any = {}): Observable<DistributorRoleUser[]> {
    const params = objectToParams(searchParams);
    return this.http.get<DistributorRoleUser[]>(`${USER_DISTRIBUTOR_URL}/v1/distributor/role`, { params })
      .pipe(map((res: any) => res.data));
  }
}