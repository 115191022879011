import { Injectable } from '@angular/core';
import { AgentUser, AgentUserCreatePayload, AgentUserSearchParams, AgentUserSupervisorsData, AgentUserSupervisorsSearchParams, AgentUserUpdatePayload } from '../../../models/agent-user';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { USER_DISTRIBUTOR_URL } from '../../../../../environments/environment';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import { IPageableList } from '../../../models/pageable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgentUserService {
  constructor(private readonly http: HttpClient) { }

  searchAgentUser(searchAgentUserParams: Partial<AgentUserSearchParams>): Observable<IPageableList<AgentUser>> {
    const params = objectToParams(searchAgentUserParams);
    return this.http.get<IPageableList<AgentUser>>(`${USER_DISTRIBUTOR_URL}/v1/distributor-agent-user`, { params });
  }

  createAgentUser(payload: AgentUserCreatePayload): Observable<AgentUser> {
    return this.http.post<AgentUser>(`${USER_DISTRIBUTOR_URL}/v1/distributor-agent-user`, payload);
  }

  updateAgentUser(payload: AgentUserUpdatePayload): Observable<AgentUser> {
    return this.http.put<AgentUser>(`${USER_DISTRIBUTOR_URL}/v1/distributor-agent-user`, payload);
  }

  removeAgentUser(agentUserId: number): Observable<any> {
    return this.http.delete<any>(`${USER_DISTRIBUTOR_URL}/v1/distributor-agent-user/${agentUserId}`);
  }

  findPossibleSupervisors(searchAgentUserSupervisorsParams: Partial<AgentUserSupervisorsSearchParams>): Observable<Array<AgentUser>> {
    const params = objectToParams(searchAgentUserSupervisorsParams);
    return this.http.get<AgentUserSupervisorsData>(`${USER_DISTRIBUTOR_URL}/v1/distributor-agent-user/supervisors`, { params })
      .pipe(map(res => res.data));
  }
}